:focus {
  outline: none;
}

body {
  font-family: 'Noto Sans CJK JP', 'Avenir', 'Terminal', 'Verdana', 'Ubuntu', 'Helvetica Neue','Helvetica','Arial',sans-serif;
}

.defaultFont {
  font-family: 'Noto Sans CJK JP', 'Avenir', 'Terminal', 'Verdana', 'Ubuntu', 'Helvetica Neue','Helvetica','Arial',sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #f3f3f3;
/*
  height: 10vh;
*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
/*
  width: 100%;
  display: table;
  font-size: calc(10px + 2vmin);
*/
  position: relative;
  text-align: left;
  color: #803030;
  border-bottom: 2px solid #803030;
}

@media screen and (max-width:1080px) {
  .header {
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    text-align: left;
    color: #803030;
    border-bottom: 2px solid #803030;
  }
}

.noBottom {
  border-bottom: 0px solid #803030;

}

.titleMenu {
  position: fixed;
  top: 0;
  height: 2em;
  z-index: 100;
  background-color: #f3f3f3;
}

.titleMenuPadding {
  padding-top: 2em;
}

.fullScreenHelp {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100%;
  width: 100%;
  
}

.headerHelp {
  display: none;
}

@media screen and (max-width:1080px) {
  .headerHelp {
    position: fixed;
    top: 0;
    height: 2em;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0em;
    background-color: #f3f3f3;
  }
}

.headerHelpPaddng {
  display: none;
}

@media screen and (max-width:1080px) {
  .headerHelpPaddng {
    display: flex;
    padding-top: 0.3em;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
  white-space: nowrap;
/*  height: 100%; */
/*  min-width: 18em; */
  border-right: 2px solid #803030; 
}

.helpText {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.topic {
  border: 2px solid #e0c070;
  border-radius: 20px;
  margin: 1em;
  padding: 1em;
  text-align: left;
}

.simpleBox {
  border: 1px solid #afafaf;
  padding: 1em;
  text-align: left;
}

.code {
  overflow-x: auto;
  background: #101010;
  padding: 0.7em;
  text-align: left;
  font: monospace;
  font-size: 0.7em;
  color: white;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowSpColumn {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width:1080px) {
  .flexRowSpColumn {
    display: flex;
    flex-direction: column;
  }
}

.alignItemsStretch {
  align-items: stretch;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.alignItemsFlexEnd {
  align-items: flex-end;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnNowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.width100 {
  width: 100%;
}

.baseline {
  align-items: baseline;
}

.verticalAlignMiddle {
  vertical-align: middle;
}

.right {
  position: absolute;
  right: 0;
  top: 0;
}

.margin01 {
  margin: 0em 1em;
}

.margin0302 {
  margin: 0.3em 0.2em;
}

@media screen and (max-width:1080px) {
  .marginSpSide0 {
    margin-left: 0em;
    margin-right: 0em;
  }
}

.marginHelp {
  margin: 0.5em 1em;
}

.marginSp02 {
  margin: 0.2em;
}

@media screen and (max-width:1080px) {
  .marginSp02 {
    margin: 0.2em;
  }
}

.marginSide0p5 {
  margin: 0em 0.5em;
}

.tmp1 {
/*
  position: absolute;
  left: 0;
  top: 0;
*/
  border: 1px solid blue;
}

.title {
  margin: 0em 1em;
}

.notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 1em;
  margin: 4px 1em 0em;
  padding: 0em 1em 0.1em;
  border-radius: 10px;
  background: #f3f3f3;
/*  background: #f9f9ff; */
}

.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0.3em 1em;
}

@media screen and (max-width:1080px) {
  .input {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    margin: 0.3em 0em;
    font-size: 2em;
  }
}

.mainMenu {
/*
  border: 5px solid green;
*/
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100%;
  margin: 0.3em 1em;
/*
  position: absolute;
  right: 0;
  top: 0;
  display: table-cell;
*/
}

.alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0.3em 0em;
}

.alignCenterNoMargin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.insertInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 3em;

}

@media screen and (max-width:1080px) {
  .insertInput {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
    margin-top: 0em;
  }
}

.insertLabel {
  margin-right: 0.5em;
  width: 6em;
}

@media screen and (max-width:1080px) {
  .insertLabel {
    margin-right: 0em;
    text-align: left;
  }
}

.revokedCheckBox {
  font-size: 0.5em;
  width: 4em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.transparent {
  background-color: transparent;
}

.disabled {
  background-color: #bfbfbf;
}

.italic {
  font-style: italic;
}

.topRightClose {
  position: absolute;
  top: 0em;
  right: 0em;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: #803030;
  font-size: 1em; 
}

.attachedImage {
  height: 9em;
  width: 9em;
}

.attachedImage2 {
  height: 5.3em;
  width: 5.3em;
}

.attachedImage2Outer {
  height: 5.7em;
  width: 5.7em;
  margin: 0.3em 0.3em;
}

/* -------------------------------- */

.App-link {
  color: #61dafb;
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

ul {
  list-style: none;
}

.disc {
  list-style: disc;
}

.circle {
  list-style: circle;
}

td {
  padding: 0.2em 1em;
  border: 1px solid #bfbfbf;
}

.body {
  background: #f3f3f3;
/*
  height: 90vh;
*/
  padding: 0;
  margin: 0;
}

.box1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding: 0.5em 0.5em;
  margin: 0.3em 0.3em;
  background: #c0efcf;
  border-radius: 8px;
}

.boxSignatureRevoked {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding: 0.5em 0.5em;
  margin: 0.3em 0.3em;
  background: #9f9f9f;
  border-radius: 5px;
}

.boxCertificateCore {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin: 0.3em 0.3em;
  padding: 0.5em 0.5em;
  border-radius: 8px;
  background: #c0c0ff;
}

.boxCertificateCoreInnerTop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 0.25em;
  border-radius: 8px;
  background: #c0c0ff;
}

.boxCertificateCoreInnerBottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin-top: 0.25em;
  border-radius: 8px;
  background: #c0c0ff;
}

.box2 {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0.3em 0.3em;
  padding: 0.5em 0.5em;
  border-radius: 8px;
  background: #c0c0ff;
}

.boxTargetCertificateRevoked {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0.3em 0.3em;
  padding: 0.5em 0.5em;
  border-radius: 8px;
  background: #9f9f9f;
}

.boxCertificateRevoked {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin: 0.3em 0.3em;
  padding: 0.5em 0.5em;
  border-radius: 8px;
  background: #9f9f9f;
}

.boxCertificateRevokedInnerTop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 0.25em;
  border-radius: 8px;
  background: #9f9f9f;
}

.box4 {
  position: relative;
  width: 600px;
  height: 3em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.buttonBack {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.3em 0.3em;
  padding: 0.5em 0.5em;
  border: 2px solid #803030;
  border-radius: 8px;
  background: #f3f3f3;
}

.buttonBackCertifier {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.3em 0.3em;
  padding: 0.5em 0.5em;
  border: none;
  border-radius: 8px;
  background: #c0efcf;
}

.messageArea {
  height: 50vh;
  font-family: 'Noto Sans CJK JP', 'Avenir', 'Terminal', 'Verdana', 'Ubuntu', 'Helvetica Neue','Helvetica','Arial',sans-serif;
  font-size: 1em;
}

.monoFont {
  font: monospace;
  font-size: 1em;
}

.fontRed {
  color: red;
}

.colorRed {
  color: red;
}

.backgroundColorBimyonaWhite {
  background-color: #f3f3f3;
}

.font1p5 {
  font-size: 1.5em;
}

.font1Sp1p5 {
  font-size: 1em;
}

.font1Sp2 {
  font-size: 1em;
}

.font1Sp08 {
  font-size: 1em;
}

.font1Sp06 {
  font-size: 1em;
}

@media screen and (max-width:1080px) {
  .font1Sp1p5 {
    font-size: 1.5em;
  }

  .font1Sp2 {
    font-size: 2em;
  }

  .font1Sp08 {
    font-size: 0.8em;
  }

  .font1Sp06 {
    font-size: 0.6em;
  }
}

.width60 {
  width: 60%;
}

.width60Sp90 {
  width: 60%;
}

.heightSp3 {
}

@media screen and (max-width:1080px) {
  .widthSp100 {
    width: 100%;
  }

  .widthSp90 {
    width: 90%;
  }

  .width60Sp90 {
    width: 90%;
  }

  .heightSp3 {
    height: 3em;
  }

}

.height50 {
  height: 50vh
}

.height20 {
  height: 20vh
}

/*
.box2 .box-title {
  position: absolute;
  display: inline-block;
  top: 2px;
  left: 10px;
  padding: 0 9px;
  line-height: 1;
  background: #f3f3f3;
  color: #c0c0ff;
  border-radius: 8px;
}
*/

.no {
  padding: 0em 0.2em;
  margin: 0.2em 0.2em;
  background: #f3f3f3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.addressSmall {
  font-size: 0.7em;
  font-family: monospace;
}

.addressBig {
  font-size: calc(4px + 2vmin);
  font-family: monospace;
}

.signature {
  font-size: calc(2px + 1.5vmin);
  font-family: monospace;
}

.fontSize1 {
  font-size: calc(4px + 1.5vmin);
}

.fontSize2 {
  font-size: 0.64em;
}

.fontSize1p5 {
  font-size: 1.5em;
}

.fontSize0p7 {
  font-size: 0.7em;
}

.signatureWarn {
  font-size: calc(2px + 1.5vmin);
  font-family: monospace;
  color: #ff0000;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.2em 0.2em;
}

.centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.2em 0.2em;
}

.leftText {
  text-align: left;
}

.textLeft {
  text-align: left;
}

.rightText {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.button0 {
  padding: 0.2em 1.2em;
  font-size: 1em;
  border: none;
  background-color: transparent;
}

@media screen and (max-width:1080px) {
  .button0 {
    padding: 0.2em 0em;
/*
    font-size: 1em;
*/
    border: none;
    background-color: transparent;
  }
}

.button1 {
  height: 1.5em;
  font-size: 0.8em;
  color: #c04737;
  border-top: 1px solid #803030;
  border-bottom: 1px solid #803030;
  border-left: 2px solid #803030;
  border-right: 2px solid #803030;
  border-radius: 10px;
/*
  background-color: transparent;
*/
}

@media screen and (max-width:1080px) {
  .button1 {
    height: 1.5em;
    width: 100%;
    font-size: 0.8em;
    color: #c04737;
    border-top: 1px solid #803030;
    border-bottom: 1px solid #803030;
    border-left: 2px solid #803030;
    border-right: 2px solid #803030;
    border-radius: 10px;
/*
    background-color: transparent;
*/
  }
}

.button2 {
  font-size: 1em;
  border: none;
  background-color: transparent;
}

.textfield1 {
  border-top: none;
  border-bottom: 2px solid #803030;
  border-left: none;
  border-right: none;
  background-color: transparent;
}

.textfield1:focus {
  border-top: none;
  border-bottom: 2px solid #803030;
  border-left: none;
  border-right: none;
  border-radius: 5px;
  background-color: #e9e9e9;
}

.textfield1Disabled {
  border-top: none;
  border-bottom: 2px solid #803030;
  border-left: none;
  border-right: none;
  border-radius: 5px;
  background-color: #bfbfbf;
}

.focusEffect01:focus {
  background-color: #e9e9e9;
}

.focusEffect02:focus {
  background-color: #b0b0ee;
}

.focusEffectCertifier:focus {
  background-color: #b0dfbf;
}

.focusEffectRevoked:focus {
  background-color: #7f7f7f;
}

.selectbox1 {
/*
  height: calc(8px + 2vmin);
*/
  font-size: 1em;
  border-top: none;
  border-bottom: 2px solid #803030;
  border-left: none;
  border-right: none;
  background-color: transparent;

}

@media screen and (max-width:1080px) {
  .selectbox1 {
  /*
    height: calc(8px + 2vmin);
  */
    font-size: 0.8em;
    border-top: none;
    border-bottom: 2px solid #803030;
    border-left: none;
    border-right: none;
    background-color: transparent;
  }
}

.selectboxBless1 {
  width: 272px;
  height: 2.2em;
  margin: 0.5em 2px;
  font-size: 1em;
  border-top: none;
  border-bottom: 1px solid #803030;
  border-left: none;
  border-right: none;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.selectboxBless1:focus {
  width: 272px;
  height: 2.2em;
  margin: 0.5em 2px;
  font-size: 1em;
  border-top: none;
  border-bottom: 1px solid #803030;
  border-left: none;
  border-right: none;
  border-radius: 0.3em;
  background-color: rgba(183,183,183,0.5);
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0em;
}

.monaEm {
  width: 1.4em;
  height: 1.2em;
}

.oneFiveEm {
  width: 1.5em;
  height: 1.5em;
}

.twoEm {
  width: 2em;
  height: 2em;
}

.noDisplay {
  display: none;
}

.displayNoneSpBlock {
  display: none;
}

@media screen and (max-width:1080px) {
  .displaySpNone {
    display: none;
  }

  .displayNoneSpBlock {
    display: block;
  }
}

.border {
  border: 2px solid green;
}

.cornerBorder {
  border-top: 3px solid #f3f3f3;
  border-left: 3px solid #f3f3f3;
}

.borderRight {
  border-right: 2px solid #803030; 
}

.borderNone {
  border: none;
}

.relative {
  position: relative;
}

.treeNumber {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  color: white;
  font-size: 0.6em; 
}

.bottomRight {
  position: absolute;
  bottom: 0em;
  right: 0em;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  color: #803030;
  font-size: 0.6em; 
  border-radius: 8px 0 8px 0;
}

.topRight {
  position: absolute;
  top: 0em;
  right: 0em;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  color: #803030;
  font-size: 0.6em; 
  border: none;
  border-radius: 10px;
  background-color: transparent;
}

.fixed {
  position: fixed;
}

.between {
  justify-content: space-between;
}

.checkOn {
  width: calc(0.1em + 20px);
  height: calc(0.1em + 20px);
  border-color: #803030;
  border-style: solid;
  border-radius: calc(0.1em + 20px);
  background-color: #ff7f4f;
}

.checkOff {
  width: calc(0.1em + 20px);
  height: calc(0.1em + 20px);
  border-color: #803030;
  border-style: solid;
  border-radius: calc(0.1em + 20px);
  background-color: #7f8f9f;
}

.focusEffectBorder:focus {
  border-width: 0.3em;
}

.checkOnString {
  border: none;
  background-color: transparent;
  font-size: 0.6em;
  color: #ff7f4f;
}

.checkOffString {
  border: none;
  background-color: transparent;
  font-size: 0.6em;
  color: #803030;
}

.top0 {
  position: absolute;
  top: 0
}

.wordBreakBreakAll {
  word-break: break-all;
}

@media screen and (max-width:1080px) {
  .wordWrapSpBreakWord {
    word-wrap: break-word;
  }
}

.marginTop1p5 {
  margin-top: 1.5em;
}

.paddingTopSp2 {
  padding-top: 0em;
}

@media screen and (max-width:1080px) {
  .paddingTopSp2 {
    padding-top: 2em;
  }
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.marginTop1 {
  margin-top: 1em;
}

.invisibleCheckBox {
  display: none;
}

.buttonBless1 {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #c0d0ff;
  cursor: pointer;
}

.buttonBless1:focus {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.buttonBless1:active {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.buttonBless2 {
  height: 3.8em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #c0d0ff;
  cursor: pointer;
}

.buttonBless2:focus {
  height: 3.8em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.buttonBless2:active {
  height: 3.8em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.boxBless1 {
  position: relative;
  width: 272px;
  height: 2.2em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.invisibleCheckBox:checked +.boxBless1 {
  background-color: rgba(183,183,183,0.5);
}

.textLine1 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 50%;
  width: 96%;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

.textArea2 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 70%;
  width: 96%;
  resize: none;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: auto;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

.label1Unfilled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
}

.invisibleCheckBox:checked + .label1Unfilled {
  font-size: 0.5em;
}

.label1Filled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
  font-size: 0.5em;
}

.popup2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.invisible {
  display: none;
}

.size2x2 {
  height: 2.2em;
  width: 2.2em;
}

/* -------------------------------------- */


.popUpCheckBox, .popUp, .popUpRight {
  display: none;
/*
  opacity: 0;
*/
}

.popUpCheckBox:checked + .popUp {
  display: flex;
  justify-content: center;
  align-items: center;
/*
  opacity: 1;
*/
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
/*
  border: 5px solid blue;
*/
}

.popUpCheckBox:checked + .popUpRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
/*
  opacity: 1;
*/
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
/*
  border: 5px solid blue;
*/
}

.popuptrue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  font-size: 16px;
}

.popupfalse {
  display: none;
}

.popupundefined {
  display: none;
}

.closePopUp {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.3);
  z-index: 10000;
  cursor: pointer;
}

.popUpWrap {
/*
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 80%;
  height: 90%;
  max-width: 540px;
  height: fit-content;
*/
  position: relative;
  max-width: 85%;
  padding: 1em 1em;
  background: #fff;
  border-radius: 10px;
  z-index: 10001;
  word-wrap: break-word;
/*
  overflow-wrap: break-word;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style:none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
*/
}

.popUpWrap2 {
  position: relative;
  max-height: 90%;
  max-width: 90%;
  z-index: 10001;
  border-radius: 10px;
  background: #fff;
  padding: 1.5em 1em;
  word-wrap: break-word;
  overflow-y: auto;
}

.popUpMenu {
/*
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 80%;
  height: 90%;
  max-width: 540px;
  height: fit-content;
*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
  height: 100%;
  padding: 1em 1em;
  background: #fff;
  z-index: 10001;
  word-wrap: break-word;
/*
  overflow-wrap: break-word;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style:none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
*/
}

@media screen and (max-width:1080px) {
  .popUpMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 1em 0.5em;
    background: #fff;
    z-index: 10001;
    word-wrap: break-word;
  }
}

/*
.messageFade-enter {
  color: white;
  opacity: 1;
}
*/

.messageFade-enter-active {
  z-index: 10001;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #803030;
  opacity: 0;
  transition: opacity 2000ms ease-out 3000ms;
  padding: 0.5em 1em;
  background-color: #ffe3b3;
  border-radius: 5px;
}

.messageFade-enter-done {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade-exit {
  display: none;
  color: #803030;
  opacity: 0;
}

/*
.messageFade-exit-active {
  color: green;
  opacity: 0.2;
  transition: all 300ms ease-out;
}

.messageFade-exit-done {
  color: white;
  opacity: 0;
}
*/

@media screen and (max-width:1080px) {
  .widthSp {
    width: 96vw;
  }

  .width90Sp {
    width: 90vw;
  }

  .widthWithButton1Sp {
    width: calc(96vw - 3.4em);
  }

  .widthWithButton1NarrowSp {
    width: calc(92vw - 3.4em);
  }
}

@media screen and (max-width:1080px) {
  .maxWidth30Sp {
    max-width: 30%;
  }

  .width50Sp {
    width: 50%;
  }

  .width100Sp {
    width: 100%;
  }

  .widthAutoSp {
    width: auto;
  }

  .height2emSp {
    height: 2em;
  }

  .flexColumnNowrapSp {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .justifyContentSpaceAroundSp {
    justify-content: space-around;
  }

  .justifyContentFlexEndSp {
    justify-content: flex-end;
  }

  .marginSide0p2Sp {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }

  .wordWrapBreakWordSp {
    word-wrap: break-word;
  }
}

